import React from 'react';
import { getPublicTalent } from 'modules/talentDashboard/utils';
import { TalentPublicPreview } from 'modules/profilePreview/components';

interface Props {
  serverData: {
    user?: TalentDataPreview;
    error?: ApiErrorData;
  };
}

const TalentPublicProfile: React.FC<Props> = ({ serverData }) => {
  const { user, error } = serverData;

  return (
    <>
      <TalentPublicPreview user={user} error={error} />
    </>
  );
};

export async function getServerData(context: {
  params: Record<string, string>;
}) {
  const talentPublicId = context.params.talentPublicId;

  const { error, user } = await getPublicTalent(talentPublicId);

  const data = {
    props: {
      error,
      user,
    },
    headers: {
      'Cache-Control': 'public, max-age=0, must-revalidate',
    },
  };

  if (!error) return data;

  return {
    status: error.status,
    ...data,
  };
}

export default TalentPublicProfile;
